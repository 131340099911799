import * as React from 'react';
import { Layout } from '../src/layouts/error';
import { Button } from '../src/components/Button';
import { Icon } from '../src/components/Icon';
import { useThemeStyles } from '../src/utils/useThemeStyles';
import * as Sentry from '@sentry/nextjs';
import { useTranslation } from '../src/utils/useTranslation';

const CustomError = ({ statusCode, hasGetInitialPropsRun, err }) => {
  const classes = useThemeStyles('Layout');
  const { t } = useTranslation();

  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
  }

  console.log('error page');

  return (
    <Layout title={t(`pages:error.unavailable.title`)}>
      <div className={classes.contentWrapper}>
        <div className={classes.errorPage}>
          <h3 className={classes.errorPageHeader}>{t(`pages:error.unavailable.text`)}</h3>
          <p className={classes.errorPageDescription}>{t(`pages:error.message`, { statusCode })}</p>
          <Button
            type='primary'
            onClick={() => {
              window.location.href = '/';
            }}
          >
            <Icon type='arrow-left' />
            {t(`pages:toMainPage`)}
          </Button>
        </div>
      </div>
    </Layout>
  );
};

function getInitialProps({ res, err, asPath }) {
  const hasGetInitialPropsRun = true;
  let statusCode;
  // If the res variable is defined it means nextjs
  // is in server side
  if (res) {
    statusCode = res.statusCode;
  } else if (err) {
    // if there is any error in the app it should
    // return the status code from here
    statusCode = err.statusCode;
  } else {
    // Something really bad/weird happen and status code
    // cannot be determined.
    statusCode = null;
  }

  if (err) {
    Sentry.captureException(err);
    return { statusCode, hasGetInitialPropsRun, err };
  }

  Sentry.captureException(new Error(`statusCode: ${statusCode}; path: ${asPath}`));
  return { statusCode, hasGetInitialPropsRun, err };
}

CustomError.getInitialProps = getInitialProps;

export default CustomError;
